import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./App.css";
import ChoreList from "./components/ChoreList";
import { fetchChores, updateChore } from "./components/api";

function App() {
  const [chores, setChores] = useState([]);
  const [checkedChores, setCheckedChores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchChores();
        setChores(data);
        setCheckedChores(data.filter((chore) => chore.is_checked === true));
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleCheck = async (event, chore) => {
    const is_checked = event.target.checked;

    try {
      await updateChore(chore.id, is_checked);

      const updatedCheckedChores = is_checked
        ? [...checkedChores, chore]
        : checkedChores.filter(
            (existingChore) => existingChore.id !== chore.id
          );

      setCheckedChores(updatedCheckedChores);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="app">
      <ChoreList
        chores={chores}
        type="daily"
        checkedChores={checkedChores}
        onCheck={handleCheck}
      />
      <ChoreList
        chores={chores}
        type="weekly"
        checkedChores={checkedChores}
        onCheck={handleCheck}
      />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

export default App;
