// ChoreItem.js
import React from "react";

const ChoreItem = ({ chore, isChecked, onCheck }) => {
  return (
    <div key={chore.id}>
      <input
        value={chore.id}
        type="checkbox"
        onChange={(event) => onCheck(event, chore)}
        checked={isChecked ? "checked" : ""}
      />
      <span className={isChecked ? "checked-item" : "not-checked-item"}>
        {chore.chores}
      </span>
    </div>
  );
};

export default ChoreItem;
