// ChoreList.js
import React from "react";
import ChoreItem from "./ChoreItem";

const ChoreList = ({ chores, type, checkedChores, onCheck }) => {
  const filteredChores = chores
    .filter((chore) => chore.type === type)
    .sort((a, b) => a.id - b.id);

  return (
    <div className={`${type}Chores`}>
      <div className="title">{`${type.charAt(0).toUpperCase()}${type.slice(
        1
      )} Chores:`}</div>
      <div className="list-container">
        {filteredChores.map((chore) => (
          <ChoreItem
            key={chore.id}
            chore={chore}
            isChecked={checkedChores.includes(chore)}
            onCheck={onCheck}
          />
        ))}
      </div>
    </div>
  );
};

export default ChoreList;

// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Fill fridge with water', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean room', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean bathroom', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Dishes', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Counters', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean dinner table', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean floor in pantry', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Pick up everything on floor in downstrairs hallway/kitchen/living room', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Take out the trash', false, 1, 'daily');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean dog poop on side of house', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean trash in backyard', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Vacuum room', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean windows (back door, office, living room)', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Clean fridge inside and out (remove items, wipe down, replace items)', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Wipe down kitchen cabinets', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Empty kitch drawers and clean', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Pull weeds in front planter', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Barrels to curb (Wednesday)', false, 1, 'weekly');
// INSERT INTO chorelist (Chores, is_checked, user_id, type) VALUES ('Barrels to side of house (Thursday)', false, 1, 'weekly');

// CREATE TABLE chorelist (
//     id SERIAL PRIMARY KEY,
//     chores VARCHAR,
//     is_checked BOOLEAN,
//     user_id INTEGER,
//     type VARCHAR
// );
