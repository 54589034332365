// api.js
const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://whatachore.com:3001"
    : "https://localhost:3001";

export const fetchChores = async () => {
  try {
    const response = await fetch(`${API_URL}/chores`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching chores");
  }
};

export const updateChore = async (choreId, is_checked) => {
  try {
    await fetch(`${API_URL}/chores/${choreId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ is_checked }),
    });
  } catch (error) {
    throw new Error("Error updating chore");
  }
};
